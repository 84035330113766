import { defaultKcProps, getKcContext } from "keycloakify";
import ReactDOM from "react-dom";
import { memo } from "react";
import { makeStyles } from "./useClassNames";
import { KcApp as KcAppBase } from "keycloakify/lib/components/KcApp";
// logo was minified at https://www.svgminify.com/ for use with react
import logoUrl from "./logo.svg";
export type Props = {
  kcContext: any;
};


const { kcContext } = getKcContext(
  { 
    //  "mockPageId": "login.ftl",
       "mockData": [
  {
    "pageId": "login.ftl",
    "locale": {
      "currentLanguageTag": "de" //When we test the login page we do it in french
    }
  }]});

if( kcContext === undefined ){
    throw new Error(
        "This app is a Keycloak theme" +
        "It isn't meant to be deployed outside of Keycloak"
    );
}


const KcApp = memo((props: Props) => {
  const { kcContext } = props;

  const { classes } = useStyles();

  const kcProps = {
      ...defaultKcProps,
      "kcHtmlClass": [...defaultKcProps.kcHtmlClass, classes.kcHtmlClass],
      "kcLoginClass": [...defaultKcProps.kcLoginClass, classes.kcLoginClass],
      "kcFormCardClass": [...defaultKcProps.kcFormCardClass, classes.kcFormCardClass],
      "kcButtonPrimaryClass": [
          ...defaultKcProps.kcButtonPrimaryClass,
          classes.kcButtonPrimaryClass,
      ],
      "kcInputClass": [...defaultKcProps.kcInputClass, classes.kcInputClass],
  };

  switch (kcContext.pageId) {
      // case "login.ftl":
      //     return <Login {...{ kcContext, ...kcProps }} />;
      // case "terms.ftl":
      //     return <Terms {...{ kcContext, ...kcProps }} />;
      // case "login-update-profile.ftl":
      //     return <LoginUpdateProfile {...{ kcContext, ...kcProps }} />;
      // case "register-user-profile.ftl":
      //     return <RegisterUserProfile {...{ kcContext, ...kcProps }} />;
      default:
          return <KcAppBase {...{ kcContext, ...kcProps }} />;
  }
});


const useStyles =  makeStyles({ "name": { KcApp } })(
  theme=>({
      "kcLoginClass": {
          "& #kc-locale": {
              "zIndex": 5
          }
      },
      "kcHtmlClass": {
          "& body": {
              
            "background": `url(${
              logoUrl
          }) no-repeat center top fixed`,
          "backgroundSize": "400px 100px",
              "fontFamily": theme.typography.fontFamily,
          },
          "background": `#ffffff !important`,
          "& a": {
              "color": `#000000 !important`
          },
          "& #kc-current-locale-link": {
              "color": `#999999 !important`
          },
          "& label": {
              "fontSize": 14,
              "color": `#444444`,
              "fontWeight": "normal"
          },
          "& #kc-page-title": theme.typography.h2 as any,
          "& #kc-header-wrapper": {
              "visibility": "hidden"
          },
          "& #kc-form:after": {
            "color": `#444444`,
            "fontWeight": "bold",
            "fontSize" : "14px",
            "textAlign": "center",
            "top": 20,
            "left": 7,
            "position": "relative",
            "content" : '"Für die Anmeldung benötigst du deine CODie-Zugangsdaten."'
          }
      },
      "kcFormCardClass": {
          "borderRadius": 10
      },
      "kcButtonPrimaryClass": {
          "backgroundColor": "unset !important",
          "backgroundImage": "unset !important",
          "borderColor": `#004444 !important`,
          "borderWidth": "2px !important",
          "borderRadius": `20px !important`,
          "color": `#003644 !important`,
          "textTransform": "uppercase"

      },
      "kcInputClass": {
          "borderRadius": "unset !important",
          "border": "unset !important",
          "boxShadow": "unset !important",
          "borderBottom": `1px solid #003644 !important`,
          "&:focus": {
              "borderColor": `unset !important`,
              "borderBottom": `1px solid #fbba00 !important`,
          }

      }
  })
);

ReactDOM.render(
    <KcApp
        kcContext={kcContext}
    />,
    document.getElementById("root"),
);